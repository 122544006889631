microflow.controller("UISignaturePadCtrl", [
  "$scope",
  "$attrs",
  "$element",
  function ($scope, $attrs, $element) {
    $(document).ready(function () {
      var stateName;
      if ($attrs.ngModel) {
        stateName = $attrs.ngModel.replace("State.", "");
      }
      var wrapper = $element[0];
      var canvas = wrapper.shadowRoot.querySelector("canvas");
      $scope.signaturePad = new SignaturePad(canvas, {});
      $scope.signaturePad.penColor = $attrs.pgPenColor || "rgb(0, 0, 0)";
      $scope.signaturePad.minWidth = $attrs.pgMinWidth || 0.5;
      $scope.signaturePad.maxWidth = $attrs.pgMaxWidth || 2.5;
      $scope.resizeCanvas = function () {
        var ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        $scope.signaturePad.clear();
      }
        window.onresize = $scope.resizeCanvas;
        $scope.resizeCanvas();
        if (stateName && $scope.$parent.State.hasOwnProperty(stateName)) {
          var defaultState = $scope.$parent.State[stateName];
          if (defaultState) {
            $scope.signaturePad.fromDataURL(defaultState);
          }
        }
        $scope.onSave = function () {
          if (stateName) {
            $scope.$parent.State[stateName] = $scope.signaturePad.toDataURL();
            $scope.$parent.update();
          }
          var event = $.Event("save", {
            detail: {
              toDataURL: $scope.signaturePad.toDataURL(),
            },
          });
          $element.trigger($.Event(event));
        };
        $scope.onClear = function () {
          $scope.signaturePad.clear();
          if (stateName) {
            $scope.$parent.State[stateName] = "";
            $scope.$parent.update();
          }
          var event = new CustomEvent("clear", {});
          $element.trigger($.Event(event));
        };
        $scope.defaultDataURL = function (data) {
          $scope.signaturePad.fromDataURL(data);
        };
    });
  }
]);
